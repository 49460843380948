<template>
    <div class="lectureList" :class="lectureListClass">
        <h2>{{ title }}</h2>
        <div v-if="$slots.lectureListFilter" class="lectureList__filter">
            <slot name="lectureListFilter"/>
        </div>
        <div class="lectureList__wrapper">        
            <slot name="lectureList"/>
        </div>      
        <div class="lectureList__button">
            <slot name="lectureListButton"/>
        </div>  
    </div>
</template>
<script>
  export default {
   props: {
        title: {
            type: String,
            required: true,
        },

        margin: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        lectureListClass() {
            const classes = [];
            if (this.margin === true) {
                classes.push("lectureList--margin")
            }

            return classes.join(' ');
        }
    }
   }
</script>