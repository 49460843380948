<template>
  <div
    aria-live="assertive"
    class="formValidation"
    :id="id"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
