<template>
    <article class="lectureListCard"
        :class="`lectureListCard--${color}`"
    >
        <a
            class="lectureListCard__link"
            :href="`/trainings/${id}`"
        ></a>
        <div class="lectureListCard__image">
            <img
                :src="require(`../assets/images/lectures/lecturesCards/image/${image}.jpg`)" 
                alt=""
                width="300"
                height="200"
                loading="lazy"
                class="img"
            >
            <div v-if="finished" class="lectureListCard__finished">
                <img
                    src="../assets/images/icons/check.svg"
                    alt=""
                    class="icon"
                    width="22"
                    height="17"
                    loading="lazy"
                >
                Proběhlo
        </div>
        </div>
        <div class="lectureListCard__content">
            <div class="lectureListCard__content__types">
                <div class="lectureListCard__content__type">{{ getCodeBookByKeyAndValue('training_type', type) }}</div>
                <div v-if="mpsv" class="lectureListCard__content__mpsv">MPSV</div>
            </div>
            <h3>{{ title }}</h3>
            <p>{{ text }}</p>
            <div class="lectureListCard__content__parameters">
                <p v-if="location" class="lectureListCard__content__parameters__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M8.34.032A7.316 7.316 0 0 0 5.715.78a7.305 7.305 0 0 0-3.872 4.724 6.6 6.6 0 0 0-.233 1.831c-.002 1.256.27 2.331.925 3.66.722 1.464 1.663 2.718 3.178 4.232.971.97 1.98 1.837 2.928 2.513.366.261.367.261.446.211.161-.104.901-.662 1.248-.941 2.526-2.036 4.432-4.324 5.357-6.435.691-1.575.887-3.088.596-4.597-.517-2.681-2.495-4.86-5.139-5.661A7.75 7.75 0 0 0 8.34.032m1.211 1.499a5.904 5.904 0 0 1 4.144 2.264 5.8 5.8 0 0 1 1.188 2.938c.044.371.019 1.229-.045 1.572-.234 1.262-.847 2.549-1.859 3.905-.854 1.143-2.242 2.548-3.614 3.655-.266.215-.35.269-.39.247-.071-.039-.77-.613-1.145-.941a30.612 30.612 0 0 1-1.857-1.836c-1.532-1.714-2.442-3.331-2.765-4.914-.131-.642-.125-1.529.015-2.22.592-2.923 3.343-4.953 6.328-4.67m-.939 2.731A3.185 3.185 0 0 0 6.18 5.985c-.366.742-.442 1.521-.222 2.29a3.17 3.17 0 0 0 4.411 2.002c.864-.41 1.47-1.15 1.725-2.107.08-.302.108-.913.057-1.233a3.203 3.203 0 0 0-2.492-2.632 3.99 3.99 0 0 0-1.047-.043m.874 1.547c.407.119.794.435.991.809a1.689 1.689 0 0 1-.089 1.744c-.281.428-.846.726-1.376.728-.228 0-.598-.093-.801-.202a2.071 2.071 0 0 1-.367-.293A1.543 1.543 0 0 1 7.35 7.41c0-.438.129-.785.413-1.109a1.76 1.76 0 0 1 .982-.542 2.05 2.05 0 0 1 .741.05" fill-rule="evenodd"/></svg>
                    {{ location }}
                </p>
                <p v-if="date" class="lectureListCard__content__parameters__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M5.52.044a.755.755 0 0 0-.439.389c-.067.144-.07.184-.071.757v.606l-1.282.011c-1.233.01-1.291.013-1.484.078a1.94 1.94 0 0 0-1.046.908c-.209.415-.193-.166-.193 7.107 0 6.167.003 6.566.053 6.746.163.59.642 1.095 1.206 1.273l.211.066h13.05l.211-.066c.564-.178 1.043-.683 1.206-1.273.05-.18.053-.579.053-6.746 0-6.167-.003-6.566-.053-6.746a1.908 1.908 0 0 0-1.19-1.271c-.188-.062-.255-.066-1.48-.076l-1.282-.011V1.19c-.001-.573-.004-.613-.071-.757a.74.74 0 0 0-1.21-.188c-.18.187-.2.276-.213.947l-.012.608H6.516l-.012-.608c-.013-.671-.033-.76-.213-.947A.756.756 0 0 0 5.52.044m-.509 3.803c.001.367.014.584.04.656.175.49.823.643 1.214.286a.953.953 0 0 0 .149-.214c.073-.142.078-.179.09-.713l.013-.562h4.966l.013.562c.012.534.017.571.09.713.043.082.11.179.149.214a.764.764 0 0 0 1.039-.017c.191-.192.2-.229.211-.879l.01-.593h2.304l.098.088.098.087.008 1.758.008 1.757H2.489l.008-1.757.008-1.758.098-.087.098-.088H5.01l.001.547m10.492 8.56-.008 3.918-.098.087-.098.088H2.701l-.098-.088-.098-.087-.008-3.918-.008-3.917h13.022l-.008 3.917" fill-rule="evenodd"/></svg>
                    {{ date }}
                </p>
                <p v-if="topic" class="lectureListCard__content__parameters__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none"><path d="M8.285.033A8.54 8.54 0 0 0 6.3.47c-.781.28-1.702.8-2.356 1.329-.305.246-.906.847-1.15 1.149A8.142 8.142 0 0 0 1.061 6.99c-.07.51-.07 1.5 0 2.01.265 1.938 1.18 3.68 2.595 4.941a8 8 0 0 0 11.6-.966c.228-.284.638-.903.805-1.215.43-.805.747-1.8.878-2.76.061-.45.07-1.465.016-1.89-.239-1.872-1.01-3.469-2.298-4.757A7.961 7.961 0 0 0 10.353.12 8.265 8.265 0 0 0 8.285.033m1.3 1.497a6.499 6.499 0 0 1 5.58 4.425c.227.683.318 1.267.318 2.04 0 .773-.091 1.357-.318 2.04-1.246 3.747-5.521 5.532-9.044 3.776a6.134 6.134 0 0 1-1.798-1.318A6.422 6.422 0 0 1 2.565 8.88a8.183 8.183 0 0 1 0-1.77A6.531 6.531 0 0 1 4.83 3.015 6.547 6.547 0 0 1 9.585 1.53M8.278 3.254a4.579 4.579 0 0 0-1.421.456c-1.343.678-2.248 1.864-2.571 3.37-.058.267-.068.406-.068.915s.01.648.068.915c.283 1.32 1.014 2.395 2.119 3.119 1.544 1.009 3.646 1.009 5.19 0 1.105-.724 1.836-1.799 2.119-3.119.058-.267.068-.406.068-.915s-.01-.648-.068-.915a4.866 4.866 0 0 0-1.256-2.404 4.855 4.855 0 0 0-2.483-1.377c-.391-.083-1.311-.108-1.697-.045m1.386 1.517c2.118.431 3.254 2.777 2.279 4.702a3.312 3.312 0 0 1-2.087 1.704 3.298 3.298 0 0 1-3.799-1.704 3.273 3.273 0 0 1 .61-3.796c.491-.486 1.009-.77 1.65-.904a3.74 3.74 0 0 1 1.347-.002m-.911 1.648a1.623 1.623 0 0 0-.943.521 1.494 1.494 0 0 0-.399 1.055c0 .456.139.789.467 1.118.639.643 1.605.643 2.244 0 .328-.329.467-.662.467-1.118a1.56 1.56 0 0 0-.732-1.341c-.305-.197-.766-.295-1.104-.235m.598 1.099c.14.111.249.316.249.469a.617.617 0 0 1-.442.581c-.142.042-.174.042-.316 0a.593.593 0 0 1-.385-.813c.113-.245.296-.352.579-.338.17.008.218.024.315.101" fill-rule="evenodd"/></svg>
                    {{ topic }}
                </p>
                <p v-if="lector" class="lectureListCard__content__parameters__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M8.508.03C5.895.36 4.209 2.902 4.936 5.416c.282.976.94 1.846 1.831 2.419.383.247 1.044.494 1.541.578a5.214 5.214 0 0 0 1.384-.001c2.035-.346 3.537-2.112 3.538-4.157 0-.414-.038-.712-.139-1.102A4.234 4.234 0 0 0 9.831.088c-.345-.07-1-.099-1.323-.058m1.167 1.559a2.738 2.738 0 0 1 2.013 3.116 2.75 2.75 0 0 1-1.78 2.108 2.836 2.836 0 0 1-1.809.002 2.772 2.772 0 0 1-1.746-1.892c-.086-.316-.092-1.032-.011-1.335.293-1.094 1.161-1.887 2.253-2.057a3.493 3.493 0 0 1 1.08.058m-5.52 7.984a4.275 4.275 0 0 0-3.399 2.832c-.205.604-.197.471-.208 3.158L.538 18h1.5l.009-2.363c.011-2.676-.004-2.497.265-3.056a2.616 2.616 0 0 1 1.269-1.27c.58-.279.095-.256 5.419-.256s4.839-.023 5.419.256c.577.278.992.693 1.269 1.27.269.559.254.38.265 3.056L15.962 18h1.5l-.01-2.437c-.009-2.207-.015-2.459-.064-2.661-.204-.855-.585-1.551-1.169-2.131-.579-.575-1.239-.939-2.089-1.15-.254-.063-.28-.064-5.025-.069-2.624-.003-4.851.006-4.95.021" fill-rule="evenodd"/></svg>
                    {{ lector }}
                </p>
            </div>
        </div>
    </article>
</template>
<script>
    import codeBooksMixin from "@/mixins/codeBooksMixin";
    export default {
        props: {
            color: {
                type: String,
                default: 'purple',
                validator(value) {
                    return ['purple', 'blue'].includes(value)
                }
            },

            image: {
                type: String,
                required: true
            },

            type: {
                type: Number,
                required: true
            },
            
            title: {
                type: String,
                required: true
            },

            text: {
                type: String,
                required: true
            },

            location: {
                type: String
            },

            date: {
                type: String,
                required: true
            },

            topic: {
                type: String,
                required: true
            },

            lector: {
                type: String,
                required: true
            },

            finished: {
                type: Boolean,
                default: false
            },

            id: {
                type: Number,
                required: true
            },
            mpsv: {
                type: Boolean,
                default: false
            }
        },
        mixins: [codeBooksMixin]
    }
</script>