<template>
    <div :class="{'instructorCard' : true, 'instructorCard--light' : light}">
        <img 
            alt=""
            :src="require(`../assets/images/instructors/${image}`)"
            width="180"
            height="180"
            loading="lazy"
            class="instructorCard__image"
        >
		<div class="instructorCard__content">
			<div class="instructorCard__content__head">
				<h3 class="instructorCard__content__head__title">{{ name }}</h3>
                <span class="instructorCard__content__head__position">{{ position }}</span>
			</div>
            <div class="instructorCard__content__text">{{ text }}</div>
            <div class="instructorCard__content__links">
                <a :href="socialUrl" target="_blank">
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.667 15.833h-2.5v-4.67c0-2.806-3.334-2.594-3.334 0v4.67h-2.5V6.667h2.5v1.47c1.164-2.155 5.834-2.314 5.834 2.064v5.632ZM5.417 5.61a1.465 1.465 0 0 1-1.459-1.47c0-.812.654-1.47 1.459-1.47s1.458.658 1.458 1.47c0 .812-.652 1.47-1.458 1.47Zm-1.25 10.223h2.5V6.667h-2.5v9.166ZM15.833 0H4.167A4.167 4.167 0 0 0 0 4.167v11.666A4.167 4.167 0 0 0 4.167 20h11.666A4.167 4.167 0 0 0 20 15.833V4.167A4.167 4.167 0 0 0 15.833 0Z" fill="#8ED1CC" fill-rule="evenodd"/>
                    </svg>
                </a>
            </div>
		</div>
    </div>
</template>
<script>
    export default {
        props: {
            image: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            socialUrl: {
                type: String,
                required: true
            },
            light: {
                type: Boolean,
                default: false
            }
        }
    }
</script>