<template>
  <HighlightedSection
      :title="translation('contact_form-title')"
      :text="translation('contact_form-text')"
      :size="'xxxs'"
      :color="color"
  >
    <template v-slot:after>
      <form @submit="onSubmit" class="container container--xxxxs">

        <BaseAlertList
            :alerts="formAlerts"
            class="mb-4"
        />

        <!-- subject -->
        <FormInput
            :label="translation('contact_form-subject-label')"
            :required="true"
            :uuid="`subject`"
            :error="subjectError"
            v-model="subject"
        />

        <!-- message -->
        <FormInput
            :label="translation('contact_form-message-label')"
            :required="true"
            :uuid="`message`"
            :error="messageError"
            :is-textarea="true"
            v-model="message"
        />
        <!-- Button -->
        <div>
          <BaseButton
              type="submit"
              :full-width="true"
              :isDisabled="isSubmitting"
          >
            {{ translation('contact_form-submit_button') }}
          </BaseButton>
        </div>
      </form>
    </template>
  </HighlightedSection>
</template>

<script>
import BaseAlertList from "@/components/BaseAlertList";
import BaseButton from "@/components/BaseButton";
import FormInput from "@/components/FormInput";
import HighlightedSection from "@/components/HighlightedSection";
import {codeBooksHelpers} from "@/helpers/codeBooks.helper";
import {generalService} from "@/services/general.service";
import {useField, useForm} from 'vee-validate';
import {ref} from "vue";
import * as yup from 'yup';

export default {
  components: {
    FormInput,
    HighlightedSection,
    BaseButton,
    BaseAlertList
  },
  props: {
    color: {
      type: String,
      default: null
    },
  },
  setup() {
    // form level alert variable
    let formAlerts = ref(null);

    // import translations
    const {translation} = codeBooksHelpers()

    // validator config
    const validationSchema = yup.object({
      subject: yup
          .string()
          .required(() => translation('contact_form-subject-error'))
          .min(2, () => translation('global_validation-min_length', {number: 2}))
          .max(200, () => translation('global_validation-max_length', {number: 200})),
      message: yup
          .string()
          .required(() => translation('contact_form-message-error'))
          .min(2, () => translation('global_validation-min_length', {number: 2}))
          .max(2000, () => translation('global_validation-max_length', {number: 2000})),
    })

    // Creates a vee-validate form context
    const {handleSubmit, isSubmitting, resetForm} = useForm({
      validationSchema
    });

    // create a form fields with its validation state
    const {value: subject, errorMessage: subjectError} = useField('subject')
    const {value: message, errorMessage: messageError} = useField('message')

    // submit handler
    const onSubmit = handleSubmit(async values => {

      try {
        // Send data to the API
        await generalService.sendContactForm(values.subject, values.message)

        // success message
        formAlerts.value = [{'message': translation('contact_form-alert-success'), type: "success"}];

        // reset
        resetForm()
      } catch (err) {
        formAlerts.value = [{'message': translation('global_alert-general'), type: "danger"}];
      }
    });

    return {
      subject,
      subjectError,
      message,
      messageError,
      onSubmit,
      isSubmitting,
      formAlerts,
      translation
    };
  }
}
</script>