<template>
  <div class="baseDuration" v-if="duration">
    <img
        src="../assets/images/icons/clock.svg"
        alt=""
        class="icon"
        width="17"
        height="17"
        loading="lazy"
    >
    {{ durationFormat(duration) }}
  </div>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [formatMixins],
  props: {
    duration: {
      type: Number,
      default: null
    }
  }
}
</script>