<template>
  <video
      v-if="videoUrl"
      ref="videoPlayer"
      id="my-player"
      class="video-js"
      controls
      data-setup='{"fluid": true}'
      preload="auto"
      :poster="poster">
    <source :src="videoUrl" type="application/x-mpegURL">
  </video>
</template>

<script>
import videojs from 'video.js';

export default {
  data() {
    return {
      player: null,
      options: {
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        autoplay: this.autoplay,
        controlBar: {
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          liveDisplay: false,
          playbackRateMenuButton: false
        }
      }
    }
  },

  props: {
    videoUrl: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
  },

  mounted() {
    this.createPlayer();
  },

  beforeUnmount() {
    // Destroy the player instance
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    createPlayer() {
      this.player = videojs(this.$refs.videoPlayer, this.options)
    },
  },
}
</script>
