<template>
  <input
      class="baseInput"
      :class="error ? 'is-invalid' : ''"
      :type="type"
      :id="uuid"
      :placeholder="placeholder"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : false"
      v-bind:value="modelValue"
      v-on:input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    uuid: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
  }
}
</script>