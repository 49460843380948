<template>
  <textarea
      class="baseTextarea"
      :class="error ? 'is-invalid' : ''"
      :id="uuid"
      :placeholder="placeholder"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : false"
      v-on:input="$emit('update:modelValue', $event.target.value)"
      v-bind:value="modelValue"
  >{{modelValue}}</textarea>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
  }
}
</script>