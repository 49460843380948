<template>
  <FormGroup :uuid="uuid" :label="label" :required="required" :error="error" :help="help" :show-error-icon="showErrorIcon">
    <template #icon v-if="$slots.icon">
      <slot name="icon"/>
    </template>
    <template #default>
      <BaseInput
          v-if="!isTextarea"
          :type="type"
          :placeholder="placeholder"
          :uuid="uuid"
          :error="error"
          v-bind:value="modelValue"
          v-on:input="$emit('update:modelValue', $event.target.value)"
      />
      <BaseTextArea
          v-if="isTextarea"
          :placeholder="placeholder"
          :uuid="uuid"
          :error="error"
          v-bind:value="modelValue"
          v-on:input="$emit('update:modelValue', $event.target.value)"
      />
    </template>
    <template #button v-if="$slots.button">
      <slot name="button"/>
    </template>
  </FormGroup>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import BaseLabel from "@/components/BaseLabel";
import BaseTextArea from "@/components/BaseTextArea";
import FormValidation from '@/components/FormValidation'
import FormGroup from "@/components/FormGroup.vue";

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    uuid: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    showErrorIcon: {
      type: Boolean,
      default: true
    },
    isTextarea: {
      type: Boolean,
      default: false
    },
  },
  components: {
    FormGroup,
    BaseTextArea,
    BaseLabel,
    BaseInput,
    FormValidation
  }
}
</script>