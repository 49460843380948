<template>
     <h2>{{ title }}</h2>
     <slot name="lecturesArticleSection"></slot>
</template>
<script>
   export default {
    props: {
            title: {
                type: String,
                required: true,
            }
        }
    }
</script>