<template>
  <label
      class="baseLabel"
      :for="uuid"
      :class="{'baseLabel--required' : required}"
  >
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    uuid: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
  }
}
</script>