<template>
  <BaseAlert :type="style">
    {{ message }}
  </BaseAlert>
</template>
<script>
import BaseAlert from "@/components/BaseAlert";
import {mapActions} from "vuex";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'flash'
    },
    style: {
      type: String,
      default: 'danger'
    }
  },
  components: {
    BaseAlert,
  },
  created() {
    if (this.type === 'flash') {
      this.removeFlashMessage(this.id)
    } else {
      this.removeAlertMessage(this.id)
    }
  },
  methods: {
    ...mapActions("app", ["removeFlashMessage", "removeAlertMessage"]),
  }
}
</script>