<template>
  <div class="baseSelect" :class="error ? 'is-invalid' : ''">
    <select
        :id="uuid"
        :aria-describedby="error ? `${uuid}-error` : null"
        :aria-invalid="error ? true : false"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
      <option
          v-for="item in options"
          :key="item.id"
          :value="item.id"
      >
        {{ item.value }}
      </option>
    </select>
    <svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" class="baseSelect__icon">
      <path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill-rule="nonzero"/>
    </svg>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    uuid: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
  },
}
</script>
