<template>
  <a v-if="!loggedIn" :href="registrationLink">
    <slot name="notLogged">Zaregistruj se</slot>
  </a>
  <a v-else :href="link">
    <slot name="logged">Chci studovat</slot>
  </a>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    link: {
      type: String,
      default: '/courses'
    },
    registrationLink: {
      type: String,
      default: '/registration'
    },
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>