<template>
  <div class="container">
    <div
        class="centeredTitle"
        :class="{
          'centeredTitle--blue': isBlue
        }"
    >
      <div class="centeredTitle__left" v-if="this.$slots.left">
        <slot name="left"/>
      </div>
      <div class="centeredTitle__center">
        <component
            :is="isH1 ? 'h1' : 'p'"
            :class="{'h1' : !isH1}"
            class="centeredTitle__title"
        >
          <slot/>
        </component>
        <slot name="additional"/>
      </div>
      <div class="centeredTitle__right" v-if="this.$slots.right">
        <slot name="right"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isH1: {
      type: Boolean,
      default: true
    },
    isBlue: {
      type: Boolean,
      default: false
    }
  },
}
</script>