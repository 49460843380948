<template>
    <div class="lectureReview">
      <div class="lectureReview__header container">
        <h2 class="lectureReview__header-title">Příběhy absolventů</h2>
      </div>
      <div class="lectureReview__body">
        <IndexReviewCarousel/>
      </div>
    </div>
  </template>
  <script>
  import ActionButton from "@/components/ActionButton.vue";
  import IndexReviewCarousel from "@/components/IndexReviewCarousel.vue";
  import {mapGetters} from "vuex";
  
  export default {
    components: {ActionButton, IndexReviewCarousel},
    computed: {
      ...mapGetters("user", ["loggedIn"]),
    },
  }
  </script>