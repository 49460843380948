<template>
  <div
      class="baseCounter"
      :class="`baseCounter--${type}`"
  >
    <img
        :src="require(`../assets/images/baseCounter/${type}.jpg`)"
        width="300"
        height="120"
        alt=""
        loading="lazy"
    />
    <div class="baseCounter__number">
      {{ value }}{{ plus ? '+' : null }}
    </div>
    <div class="baseCounter__name">{{ text }}</div>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: "orange",
      validator(value) {
        return ['orange', 'blue', 'brown', 'pink'].includes(value)
      }
    },
    text: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    plus: {
      type: Boolean,
      default: false
    },
  }
}

</script>