<template>
  <li>
    <article class="jobsList__body-item">
      <h3 class="h4">{{ title }}</h3>
      <p>{{ description }}</p>
      <p class="jobsList__body-item__salary">
        <img
            src="../assets/images/jobsList/money.svg"
            width="15"
            height="18"
            alt=""
        >
        {{ salary }} CZK
      </p>
    </article>
  </li>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    salary: {
      type: String,
      required: true
    }
  }
}
</script>