<template>
  <div
      class="baseBadge"
      :class="`baseBadge--${color}`"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green'
    }
  }
}
</script>