<template>
  <div v-if="alerts" class="baseAlertList">
    <BaseAlert
        v-for="item in alerts"
        :key="item"
        :type="item.type">
        <div v-html="item.message"></div>
    </BaseAlert>
  </div>
</template>

<script>
import BaseAlert from "@/components/BaseAlert";
export default {
  components: {BaseAlert},
  props: {
    alerts: {
      type: Array,
      default: null
    }
  }
}
</script>