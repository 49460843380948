<template>
  <div
      class="formGroup"
      :class="{'formGroup--withIcon' : $slots.icon}"
  >
    <BaseLabel
        v-if="label"
        :label="label"
        :uuid="uuid"
        :required="required"
    />
    <div class="formGroup__wrapper">
      <slot name="icon"/>
      <slot name="default"/>
      <img
          src="../assets/images/icons/cross-circle.svg"
          v-if="error && showErrorIcon"
          alt=""
          class="formGroup__error"
          width="18"
          height="18"
          loading="lazy"
      >
      <slot name="button"/>
    </div>
    <div
        v-if="help && !error"
        :id="`${uuid}-helpBlock`"
        class="formText"
    >
      {{ help }}
    </div>
    <FormValidation
        v-if="error"
        :id="`${uuid}-error`"
    >
      {{ error }}
    </FormValidation>
  </div>
</template>

<script>
import BaseLabel from "@/components/BaseLabel";
import FormValidation from '@/components/FormValidation'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    showErrorIcon: {
      type: Boolean,
      default: true
    },
  },
  components: {
    BaseLabel,
    FormValidation
  }
}
</script>