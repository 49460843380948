<template>
  <div class="jobsList">
    <div class="jobsList__header">
      <div class="container container--xs">
        <h2>Možnosti pracovního uplatnění</h2>

        <slot name="headerText">
          <p>
            Snažíme se vykrýt nedostatek IT specialistů na trhu práce, kterých může být až 30 000! Jaké pozice se ti konkrétně nabízejí, jestliže absolvuješ tento kurz?
          </p>
        </slot>
      </div>
    </div>

    <div v-if="numberOfItems" class="jobsList__body">
      <ul
          class="jobsList__body-ul"
          :class="`jobsList__body-ul--${numberOfItems}`"
      >
        <slot/>
      </ul>
    </div>

    <div v-if="footerEnabled" class="jobsList__footer container container--sm">
      <slot name="footer">
        <ActionButton :link="link"/>
      </slot>
    </div>
  </div>
</template>
<script>
import ActionButton from "@/components/ActionButton.vue";

export default {
  props: {
    numberOfItems: {
      type: Number,
      required: true
    },
    link: {
      type: String,
      default: '/courses/uvodni-kurz'
    },
    footerEnabled: {
      type: Boolean,
      default: true
    },
  },
  components: {ActionButton},
}
</script>