<template>
    <div class="lectureTypeCard" 
        :class="`lectureTypeCard--${color}`"
    >
        <div class="lectureTypeCard__header">
            <span>{{ title }}</span>
            <img
                :src="require(`../assets/images/lectures/lecturesCards/${icon}.svg`)" 
                alt=""
                class="lectureTypeCard__icon"
                width="auto"
                height="56"
                loading="lazy"
            >
        </div>
        <p>{{ text }}</p>        
    </div>
</template>
<script>
    export default {
        props: {
            color: {
                type: String,
                default: 'purple',
            },

            title: {
                type: String,
                required: true
            },

            icon: {
                type: String
            },

            text: {
                type: String,
                required: true
            }
        }
    }
</script>