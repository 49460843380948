<template>
  <article
      class="courseCard"
      :class="{
        'courseCard--whiteBg': whiteBg,
        'courseCard--verticalOnDesktop': verticalOnDesktop,
      }"
  >
    <a
        :href="forceUrl ? forceUrl : `/courses/${data.url}`"
        :title="data.name"
        @click.prevent="openDetail"
        class="courseCard__link"
    ></a>
    <div class="courseCard__image">
      <img
          :src="data.image"
          alt=""
          width="300"
          height="200"
          loading="lazy"
      >
      <div v-if="isCompleted" class="courseCard__finished">
        <img
            src="../assets/images/icons/check.svg"
            alt=""
            class="icon"
            width="22"
            height="17"
            loading="lazy"
        >
        {{ translation('video_completed') }}
      </div>
    </div>
    <div class="courseCard__content">
      <div class="courseCard__content-wrapper">
        <div class="courseCard__info">
          <BaseBadge :color="difficultyBadgeColor">
            {{ forceBadge ? forceBadge : getCodeBookByKeyAndValue('course_difficulty', data.difficulty) }}
          </BaseBadge>
          <BaseDuration :duration="data.duration"/>
        </div>
        <component :is="`h${headingLevel}`" class="courseCard__title">
          {{ data.name }}
        </component>
        <div class="courseCard__description" v-html="data.description_short"/>
      </div>
      <div v-if="data.progress" class="courseCard__progress">
        <div class="courseCard__progress-bar" :style="{'--progress': data.progress + '%'}"></div>
        <div class="courseCard__progress-number">
          {{ data.progress + '%' }}
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import BaseBadge from "@/components/BaseBadge";
import BaseDuration from "@/components/BaseDuration";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {enrollmentsService} from "@/services/enrollments.service";
import store from "@/store";
import {mapState} from "vuex";

export default {
  components: {BaseDuration, BaseBadge},
  props: {
    data: {
      type: Object,
      required: true
    },
    whiteBg: {
      type: Boolean,
      default: false
    },
    verticalOnDesktop: {
      type: Boolean,
      default: false
    },
    headingLevel: {
      type: Number,
      default: 2
    },
    forceUrl: {
      type: String,
      default: ''
    },
    forceBadge: {
      type: String,
      default: ''
    },
  },
  mixins: [codeBooksMixin],
  computed: {
    ...mapState("user", {
      userData: (state) => state.userData,
    }),
    isCompleted() {
      return this.data.date_finished;
    },
    difficultyBadgeColor() {
      if (this.forceBadge || this.data.difficulty === 1) {
        return 'blue'
      } else if (this.data.difficulty === 2) {
        return 'yellow'
      } else {
        return 'red'
      }
    }
  },
  methods: {
    async openDetail() {
      if (this.data.date_enrolled || this.forceUrl) {
        this.redirect()
      } else {
        try {
          await enrollmentsService.enrollCourse(this.data.id, this.userData.id)
          this.redirect()
        } catch (err) {
          store.dispatch('app/addFlashMessage', {message: this.translation('global_alert-general')})
        }
      }
    },
    redirect() {
      location.href = this.forceUrl ? this.forceUrl : `/courses/${this.data.url}`;
    }
  }
}
</script>