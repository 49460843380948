<template>
  <component
      :is="isLink ? 'a' : 'button'"
      class="btn"
      :class="[
        `btn--${color}`,
        size ? `btn--${size}` : null,
        {
          'btn--fullWidth': fullWidth,
          'btn--fixedWidth': fixedWidth,
          'btn--disabled': isDisabled && isLink,
          'btn--withIcon': withIcon,
          'btn--onlyIcon': onlyIcon,
        }
      ]"
      :disabled="isDisabled && !isLink ? true : null"
      @click="handleClick"
  >
    <span><slot/></span>
  </component>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'success', 'danger'].includes(value)
      }
    },
    size: {
      type: String,
      default: null,
      validator(value) {
        return ['sm', 'md', null].includes(value)
      }
    },
    isLink: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    onlyIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e) { // prevent click to link if is disabled
      if (this.isDisabled && this.isLink) {
        e.preventDefault();
      }
    }
  }
}
</script>