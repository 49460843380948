<template>
  <div
      class="baseDropdown"
      :class="{'is-active' : isOpen}"
  >
    <button
        class="baseDropdown__button"
        type="button"
        :id="uuid"
        :aria-expanded="isOpen"
        @click.prevent="handleOpen"
    >
      {{ options[value] }}
      <img
          src="../assets/images/icons/arrow-down.svg"
          alt=""
          class="icon"
          width="15"
          height="9"
          loading="lazy"
      >
    </button>
    <ul :aria-labelledby="uuid">
      <li v-for="(row, index) in options">
        <a
            :class="{active: index === value}"
            :aria-current="index === value"
            href="#"
            @click.prevent="handleClick(index)"
        >
          {{ row }}
        </a>
      </li>
    </ul>
    <div v-if="isOpen"
         class="baseDropdown__backdrop"
         @click="handleOpen"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    uuid: {
      type: String,
      required: true
    },
    value: {
      type: String,
    },
  },
  methods: {
    handleOpen() {
      this.isOpen = !this.isOpen;
    },
    handleClick(value) {
      this.isOpen = false;
      this.$emit('changeValue', value)
    }
  }
}
</script>