<template>
  <FormGroup :uuid="uuid" :label="label" :required="required" :error="error" :help="help"
             :show-error-icon="showErrorIcon">
    <template #icon v-if="$slots.icon">
      <slot name="icon"/>
    </template>
    <template #default>
      <BaseSelect
          :uuid="uuid"
          :error="error"
          :options="options"
          :value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
      />
    </template>
    <template #button v-if="$slots.button">
      <slot name="button"/>
    </template>
  </FormGroup>
</template>

<script>
import BaseLabel from "@/components/BaseLabel";
import FormValidation from '@/components/FormValidation'
import FormGroup from "@/components/FormGroup.vue";
import BaseSelect from "@/components/BaseSelect.vue";

export default {
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default: '',
    },
    uuid: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    showErrorIcon: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
  },
  components: {
    BaseSelect,
    FormGroup,
    BaseLabel,
    FormValidation
  }
}
</script>