<template>
  <transition-group
      name="fade"
      tag="div"
      class="baseAlertList baseAlertList--fixed"
      v-if="show"
  >
    <FlashMessage
        v-if="getFlashMessages"
        v-for="(row, index) in getFlashMessages"
        :key="index"
        :id="index"
        :message="row.message"
        :style="row.type"
        :type="`flash`"
    />
    <FlashMessage
        v-if="getAlertMessages"
        v-for="(row, index) in alertMessages"
        :key="index"
        :id="index"
        :message="row.message"
        :style="row.type"
        :type="`alert`"
    />
  </transition-group>
</template>
<script>
import FlashMessage from "@/components/FlashMessage";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  components: {
    FlashMessage
  },
  computed: {
    ...mapGetters("app", ["getFlashMessages"]),
    ...mapState("app", {
      flashMessages: (state) => state.flashMessages,
      alertMessages: (state) => state.alertMessages,
    }),
    show(){
      return Object.keys(this.getFlashMessages).length !== 0 || Object.keys(this.alertMessages).length !== 0;
    }
  },
  methods: {
    ...mapActions("app", ["getAlertMessages"]),
  }
}
</script>