<template>
  <div class="indexReviewCarouselItem">
    <div class="indexReviewCarouselItem__avatar">
      <img
          alt=""
          class="indexReviewCarouselItem__avatar-image"
          :src="require(`../assets/images/IndexReviewCarousel/${image}`)"
          width="180"
          height="180"
          loading="lazy"
      >
      <img
          alt=""
          class="indexReviewCarouselItem__avatar-quote"
          src="../assets/images/IndexReviewCarousel/quote.svg"
          width="46"
          height="40"
          loading="lazy"
      >
    </div>
    <div class="indexReviewCarouselItem__name">{{ name }}</div>
    <div class="indexReviewCarouselItem__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
  }
}
</script>