<template>
  <teleport to="body">
    <Transition name="baseModal">
      <div
          v-if="isActive"
          :class="className"
          aria-label="Modal"
          role="dialog"
          aria-modal="true"
          @click.self="close('backdrop')">
        <div class="baseModal__dialog" @click.self="close('backdrop')">
          <div class="baseModal__content">
            <div v-if="$slots.header" class="baseModal__header">
              <slot name="header"></slot>
              <button
                  v-if="!disableCloseButton"
                  type="button"
                  class="baseModal__header-close"
                  aria-label="close"
                  @click.prevent.stop="close('button')">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="m20 17.563-7.653-7.579 7.576-7.645L17.563 0 9.988 7.649 2.342.076 0 2.418l7.655 7.587-7.579 7.653L2.418 20l7.593-7.66 7.65 7.583z"/>
                </svg>
              </button>
            </div>
            <button
                v-if="!$slots.header && !disableCloseButton"
                type="button"
                class="baseModal__close"
                aria-label="close"
                @click.prevent.stop="close('button')"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="m20 17.563-7.653-7.579 7.576-7.645L17.563 0 9.988 7.649 2.342.076 0 2.418l7.655 7.587-7.579 7.653L2.418 20l7.593-7.66 7.65 7.583z"/>
              </svg>
            </button>
            <div class="baseModal__body">
              <slot></slot>
            </div>
            <div v-if="$slots.footer" class="baseModal__footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </teleport>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 600,
      validator: (value) => [300, 600, 800].includes(value),
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    class: {
      type: String,
      default: "",
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: null,
      validator: (value) => [null, "primary"].includes(value),
    },
  },
  emits: ["dtcModalClose"],
  computed: {
    className() {
      return [
        "baseModal",
        `baseModal--${this.size}`,
        {
          "baseModal--noCloseButton": this.disableCloseButton,
          "baseModal--primary": this.theme,
        },
        this.class,
      ]
    },
  },
  methods: {
    close(type) {
      if (!this.isStatic || (this.isStatic && type !== "backdrop")) {
        this.$emit("dtcModalClose", this.id)
      }
    },
  },
}
</script>
