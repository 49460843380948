<template>
  <button
      v-if="loggedIn"
      type="button"
      @click="logout"
  >
    <slot/>
  </button>
</template>

<script>

import {authenticationService} from "@/services/authentication";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
  methods: {
    logout() {
      authenticationService.logout()
    }
  }
}
</script>