<template>
  <div
      class="baseAlert"
      :class="`baseAlert--${type}`"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
          :d="icon"
          fill="currentColor"
          fill-rule="evenodd"
      />
    </svg>
    <slot/>
  </div>
</template>



<script>
export default {
  props: {
    type: {
      type: String,
      default: 'danger',
      validator(value) {
        return ['success', 'warning', 'danger', 'info'].includes(value)
      }
    }
  },
  computed: {
    icon() {
      if (this.type === 'success') {
        return 'm10.75 17.292-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Z';
      } else if (this.type === 'info') {
        return 'M14.204 18.624H9.796v-1.103c.533-.197 1.102-.22 1.102-.809V11.79c0-.588-.569-.681-1.102-.878V9.81h3.305v6.903c0 .59.571.615 1.103.81v1.101ZM11.999 5.126a1.378 1.378 0 0 1 0 2.755 1.378 1.378 0 0 1 0-2.755ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Z'
      }
      return 'M12 18.618a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5ZM10.109 6.16c-.112-.707.77-1.318 1.891-1.318s2.003.611 1.891 1.318l-1.121 7.07c-.041.249-.373.44-.77.44s-.729-.191-.77-.441L10.109 6.16ZM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Z';
    }
  }
}
</script>