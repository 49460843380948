<template>
  <div class="indexReviewCarousel">
    <div class="indexReviewCarousel__wrapper">
      <div
          v-for="item in data"
          :key="item"
      >
        <IndexReviewCarouselItem
            :name="item.name"
            :image="item.image"
            :text="item.text"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IndexReviewCarouselItem from "@/components/IndexReviewCarouselItem";
import {tns} from "tiny-slider";

export default {
  data() {
    return {
      carousel: null,
      data: [
        {
          name: 'Denisa Gafová',
          image: 'denisa-gafova.jpg',
          text: '„Přednášky na ITjede jsem sledovala zdarma během volna tak, jak se mi to hodilo. Kariérní poradci z ITjede sledovali moje studijní pokroky. Na základě toho mě oslovili s velmi zajímavou pracovní nabídkou. Mám obrovskou radost, že se díky teď budu věnovat tomu, co mě baví. Dělat ekonomii nebo účetnictví by mě určitě dlouhodobě nenaplňovalo.“',
        },
        {
          name: 'Jan Svoboda',
          image: 'jan-svoboda.jpg',
          text: '„Líbilo se mi, jak lektoři sdíleli své know-how a zkušenosti naprosto bez servítek. Všechno vám řekli narovinu tak, jak je. Když si udržíte zápal, konzultanti z ITjede vás rádi propojí s firmami. Pro mě je tato cesta opět důkazem, že když vytrváte, můžete dělat cokoliv.“ ',
        },
        {
          name: 'Karel Antoš',
          image: 'karel-antos.jpg',
          text: 'Díky ITjede.cz se mi podařilo získat pozici u jedné z předních IT společností na trhu. Rychle jsem získal povědomí o MS Dynamics 365 a využití technologií v praxi, což bylo zásadní, protože jsem dříve působil v jiném oboru.',
        },
        {
          name: 'Lada Kosová',
          image: 'lada-kosova.jpg',
          text: 'Často jsem slýchala, jak je IT lukrativní obor a říkala jsem si, že bych do toho taky mohla jít. Docela mě ale vyděsily ceny klasických rekvalifikačních kurzů a neměla jsem na studium stejně moc času. Pak jsem se doslechla o ITjede.cz a zkusila jsem to. Baví mě tam, že můžu studovat kdykoliv, odkudkoliv a vlastním tempem.',
        },
      ]
    }
  },
  mounted() {
    this.createCarousel();
  },
  methods: {
    createCarousel() {
      this.carousel = tns({
        container: '.indexReviewCarousel__wrapper',
        edgePadding: this.itemWidth,
        items: 1,
        gutter: 0,
        slideBy: 1,
        useLocalStorage: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3500,
        mouseDrag: true,
        center: true,
        controls: false,
        navPosition: 'bottom',
        responsive: {
          0: {
            edgePadding: 50,
            gutter: 30,
          },
          576: {
            edgePadding: 100,
            gutter: 70,
          },
          768: {
            edgePadding: 140,
          },
          992: {
            edgePadding: 300,
            gutter: 120,
          },
          1200: {
            edgePadding: 400,
          },
          1400: {
            edgePadding: 500,
          },
          1600: {
            edgePadding: 600,
          },
          1920: {
            edgePadding: this.countLargeWidth,
          }
        }
      });
    },
    destroyCarousel() {
      this.carousel.destroy();
    }
  },
  computed: {
    countLargeWidth() {
      return window.top.innerWidth * 35 / 100
    }
  },
  unmounted() {
    this.destroyCarousel();
  },
  components: {
    IndexReviewCarouselItem
  }
}
</script>