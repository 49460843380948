<template>
  <div
      class="appCounters"
      :class="variant ? `appCounters--${variant}` : null"
  >
    <BaseCounter
        v-for="item in counters"
        :key="item"
        :type="item.type"
        :text="item.text"
        :value="item.value"
        :plus="item.plus"
    />
  </div>
</template>
<script>
import BaseCounter from "@/components/BaseCounter";

export default {
  props: {
    variant: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      counters: [
        {
          type: 'orange',
          text: 'online kurzů',
          value: 25,
          plus: false,
        },
        {
          type: 'blue',
          text: 'hodin obsahu',
          value: 100,
          plus: true,
        },
        {
          type: 'brown',
          text: 'studentů',
          value: 12000,
          plus: true,
        },
        {
          type: 'pink',
          text: 'umístěných kandidátů',
          value: 100,
          plus: true,
        },
      ]
    }
  },
  components: {
    BaseCounter
  }
}
</script>