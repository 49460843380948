<template>
  <BaseDropdown
      :options="difficulties"
      :uuid="`CourseDifficultyFilter`"
      v-bind:value="difficulty"
      v-on:changeValue="setDifficulty"
  />
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapActions, mapState} from "vuex";

export default {
  mixins: [codeBooksMixin],
  computed: {
    difficulties() {
      return {
        0: this.translation('course_difficulty-all'),
        ...this.getCodeBookByKey('course_difficulty')
      }
    },
    ...mapState("courses", {
      difficulty: (state) => state.difficulty,
    })
  },
  methods: {
    ...mapActions("courses", ["setDifficulty"]),
  },
  components: {
    BaseDropdown
  }
}
</script>