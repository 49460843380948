<template>
    <div class="instructors">
        <h2>{{ title }}</h2>
        <InstructorsLayout :items-count="3">
          <InstructorCard
            image="kamila-pilna.jpg"
            name="Jan J. Ženatý"
            position="Odborník na Security"
            text="Krátký popis instruktora. Všechny videa mají přehledné doprovodné informace, Výuka je praktická."
            socialUrl="#"
          />
          <InstructorCard
            image="karel-antos.jpg"
            name="Eliška Vyhnánková"
            position="Odborník na Security"
            text="Krátký popis instruktora. Všechny videa mají přehledné doprovodné informace, Výuka je praktická."
            socialUrl="#"
          />
          <InstructorCard
            image="lada-kosova.jpg"
            name="Jan J. Ženatý"
            position="Odborník na Security"
            text="Krátký popis instruktora. Všechny videa mají přehledné doprovodné informace, Výuka je praktická."
            socialUrl="#"
          />
        </InstructorsLayout>
    </div>
</template>
<script>
    import InstructorCard from "@/components/InstructorCard.vue";
    import InstructorsLayout from "@/components/InstructorsLayout.vue";

    export default {
        props: {
            title: {
                type: String,
                required: true
            },
        },
        components: {
          InstructorCard,
          InstructorsLayout,
        }
    }
</script>