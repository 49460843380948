<template>
  <TheHeader/>
  <main :class="className">

    <div v-if="breadcrumbWrapper" class="breadcrumbWrapper">
      <TheBreadcrumb
          :path="breadcrumb"
      />
      <slot name="breadcrumbWrapper"/>
    </div>

    <TheBreadcrumb
        v-else
        :path="breadcrumb"
    />

    <slot/>
  </main>
  <TheFooter/>
  <FlashMessages/>
  <Popup v-if="showPopup"
    url=""
    alt=""
    img=""
    cookieName=""
  />
</template>

<script>
import FlashMessages from "@/components/FlashMessages";
import TheBreadcrumb from "@/components/TheBreadcrumb";
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import Popup from "@/components/Popup.vue";

export default {
  props: {
    breadcrumb: {
      type: Array,
      default: null
    },
    breadcrumbWrapper: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: null
    },
    showPopup: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Popup,
    TheBreadcrumb,
    FlashMessages,
    TheHeader,
    TheFooter,
  }
}
</script>
