<template>
  <PageHeader :headerClass="`lectures ${detailClass}`">
    <template v-slot:image>
      <img
          src="../assets/images/lectures/pageHeader/header.jpg"
          sizes="100%"
          alt=""
          class="breadcrumbWrapper__image"
      >
    </template>
    <template v-slot:attributes>
      <slot name="attributes"/>
    </template>
    <template v-slot:default>
      <slot name="default"/>
    </template>
    <template v-slot:subtitle>
      <slot name="subtitle"/>
    </template>
  </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";

export default {
  props: {
    detail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    detailClass() {
      return this.detail ? 'pageHeader--lecturesDetail' : '';
    }
  },
  components: {
    PageHeader
  }
}
</script>
