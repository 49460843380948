<template>
    <div v-if="!tooManyItems" class="team" :class="teamClass">
      <slot/>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      itemsCount: {
        type: Number,
        default: 2
      },
    },
    computed: {
    teamClass() {
        const classes = [];
        if (this.itemsCount > 4 && this.itemsCount % 3 === 2) {
            classes.push("team--two-in-last");
        }
        if (this.itemsCount > 4 && this.itemsCount % 3 === 1) {
            classes.push("team--one-in-last");
        }
        if (this.itemsCount === 1) {
            classes.push("team--one");
        } else if (this.itemsCount === 2) {
            classes.push("team--two");
        } else if (this.itemsCount === 4) {
            classes.push("team--four");
        } else if (this.itemsCount >= 5) {
            classes.push("team--three");
        }
        
        // Toto je změna pro správné aplikování třídy pro itemsCount == 3
        if (this.itemsCount % 3 === 0) {
            classes.push("team--three");
        }
        if (this.itemsCount % 3 === 0 && this.itemsCount % 2 !== 0) {
            classes.push("team--exact-three");
        }

        return classes.join(' ');
    },
    tooManyItems() {
        return this.itemsCount > 4 && !(this.itemsCount % 3 === 0 || this.itemsCount % 3 === 1 || this.itemsCount % 3 === 2);
    }
}

  }
  </script>
  