<template>
  <div class="lecturesAbout" ref="content">
    <div class="container container--xs">
      <div v-if="$slots.lead" class="lecturesAbout__lead">
        <slot name="lead"/>
      </div>
      <div v-if="$slots.video" class="lecturesAbout__video">
        <slot name="video"/>
      </div>
      <div v-if="$slots.default" class="lecturesAbout__text">
        <slot name="default"/>
      </div>
      <div v-if="$slots.action" class="lecturesAbout__action">
        <slot name="action"/>
      </div>
    </div>
    <slot name="after"/>

    <img
        src="../assets/images/particles/laptop.svg"
        alt=""
        class="microsoft365About__particle microsoft365About__particle--0"
        width="348"
        height="290"
        loading="lazy"
    >
    <img
        src="../assets/images/particles/certificate.svg"
        alt=""
        class="microsoft365About__particle microsoft365About__particle--1"
        width="315"
        height="267"
        loading="lazy"
    >
    <img
        src="../assets/images/particles/briefcase.svg"
        alt=""
        class="microsoft365About__particle microsoft365About__particle--2"
        width="430"
        height="401"
        loading="lazy"
    >
  </div>
</template>

<script>
export default {
  name: "LecturesAbout"
}
</script>